<template>
    <div class="lg:relative">
        <div class="lg:m-2 md:m-6 m-3">
            <div class="pb-2">
                <!-- <div class="text-center my-8">
                    <form @submit.prevent="search">
                        <input type="text" name="" id="" placeholder="SEARCH FOR ARTIST OR STATE" 
                            class="placeholder-gray-600 font-bold text-black xl:w-10/12 xl:text-sm md:w-7/12 w-9/12 md:text-2xl text-md p-2 md:p-4 text-center
                            bg-gray-100 rounded-xl"
                            v-model="searchStr"
                        >
                    </form>
                </div> -->
                <div v-if="loading">
                    Please wait...
                </div>
                <div v-if="loading && result && result.artists.length == 0 && result.artists.states == 0">
                    No matching found.
                </div>
                <div v-else-if="result">

                    <router-link class="grid grid-cols-2 mt-3 gap-3 users items-center" v-for="(artist,index) in result.artists" :key="index" :to="{ name: 'ProfileWithSlug' , params: { slug: artist.slug } }">
                        <div class="flex items-center">
                            <img src="../../assets/image/default.png" alt="" class="rounded-full responsive-image profile-image-list" v-if="artist.image == null || artist.image == '' ">
                            <img :src="media_base_url+artist.image" alt="" class="rounded-full responsive-image profile-image-list" v-else>
                            <p class="pl-2 artist-list-name text-sm truncate">
                                {{index + 1}}. {{ artist.member_name }}
                            </p>
                        </div>
                        <div class="text-right w-full text-sm text-white">
                            <p>{{ artist.vote_count }} <span v-if="artist.vote_count == 1">Vote</span><span v-else>Votes</span></p>
                        </div>
                    </router-link>

                    <!-- <ul v-if="result.artists">
                        <li v-for="artist in result.artists" :key="artist.id" class="mb-1">
                            <router-link :to="{ name: 'ProfileWithSlug' , params: { slug: artist.slug } }">
                                {{ artist.member_name }} {{ artist.state ? ' - ' + artist.state.name : '' }}
                            </router-link>
                        </li>
                    </ul>

                    <ul v-if="result.states">
                        <li v-for="state in result.states" :key="state.id" class="mb-1">
                            <router-link :to="{ name : 'TopRappersWithState',params : { slug : state.slug } }">
                                {{ state.name }} - View Rankings
                            </router-link>
                        </li>
                    </ul> -->

                </div>
            </div>
        </div>
      
    </div>
</template>

<script>
import API from "../../api/http";

export default {
    name: 'TopRappers',
    data() {
        return {
            media_base_url: process.env.VUE_APP_MEDIA_BASE_URL,
            loading: true,
            result: null,
            searchStr : '',
        }
    },
    watch:{
        $route (){
            this.init()
        }
    },
    created(){
        this.searchStr = this.$route.params.string;
        this.init(this.$route.params.string);
    },
    methods: {
        init(string){
            API.get('search/' + string)
                .then((response)=> {
                    this.result = response;
                    this.loading = false;
                })
        },
        search(){
            this.init(this.searchStr);
        },
    }
}
</script>
